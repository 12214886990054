/**
 * Common button styles
 */

.smile-button,
.smile-single-link {
  background: rgba(117, 129, 134, 0.19);
  box-shadow: 0 0 0 .05em rgba(117, 129, 134, 0.19);
  border: 1px solid trasparent;

  /* TODO: autoprefixer */
  /* NOTE: disabled due to visual issues */
  /* backdrop-filter: blur(50px) brightness(1.15) saturate(1.1);
  -webkit-backdrop-filter: blur(50px) brightness(1.15) saturate(1.1); */

  transition: all 0.15s 0s linear;
  cursor: pointer;
}

.smile-button:hover,
.smile-single-link:hover {
  background-color: rgba(157, 186, 193, 0.23);
  box-shadow: 0 0 0 2px rgba(157, 186, 193, 0.23);
}

.smile-button:focus,
.smile-single-link:focus {
  box-shadow: 0 0 0 .05em rgba(255, 191, 91, 0.54);
}

.smile-button:hover:focus,
.smile-single-link:hover:focus {
  box-shadow: 0 0 0 2px rgba(255, 191, 91, 0.54);
}

.smile-button:active,
.smile-single-link:active {
  transition-duration: 75ms;
  transition-timing-function: ease-in;
}

/**
 * A nice smile-button
 */

.smile-button {
  display: block;
  position: relative;
  width: auto;

  margin-bottom: .5em;
  letter-spacing: .25em;
  transform: scale(1.01);

  padding: .45em .55em .2em .8em;
  border-radius: 2em;

  box-shadow: 0 0 0 2px rgba(117, 129, 134, 0.35);
  cursor: pointer;
}

.smile-button.primary {
  padding: .65em .9em .55em 1.2em;
  font-size: 1.5em;
}
.smile-button:hover {
  transform: scale(1.1);
  background-color: rgba(157, 186, 193, 0.15);
}
.smile-button:active {
  transform: scale(1);
}

/**
 * A link button
 */

.smile-single-link {
  width: 1.3em;
  height: 1.3em;
  max-width: 1.3em;
  max-height: 1.3em;
  margin: .05em;

  padding: .15em .1em 0.05em;
  display: inline-block;
  transform: scale(1);

  text-align: center;
  border-radius: 2em;
  cursor: pointer;
}

.smile-single-link:hover {
  transform: scale(1.15);
}

.smile-single-link:active {
  transform: scale(.95);
}

.smile-single-link.massive-icon {
  padding: .1em .1em;
}
